import { default as icons_45pageLMbYCU6oEeMeta } from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/@nuxtjs+svg-sprite@1.0.2_magicast@0.3.4_rollup@3.29.4/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue?macro=true";
import { default as create_45reviewrFOh9kbZhGMeta } from "/builds/robustastudio/midea/miraco-website/pages/[...slug]/create-review.vue?macro=true";
import { default as indexKYyuF1XOa0Meta } from "/builds/robustastudio/midea/miraco-website/pages/[...slug]/index.vue?macro=true";
import { default as about_45usnZHlEOW8nOMeta } from "/builds/robustastudio/midea/miraco-website/pages/about-us.vue?macro=true";
import { default as editOmW6EGyMkLMeta } from "/builds/robustastudio/midea/miraco-website/pages/account/addresses/[id]/edit.vue?macro=true";
import { default as indexP6eKXftqP5Meta } from "/builds/robustastudio/midea/miraco-website/pages/account/addresses/index.vue?macro=true";
import { default as newo4CFvxfiXDMeta } from "/builds/robustastudio/midea/miraco-website/pages/account/addresses/new.vue?macro=true";
import { default as _91id_93toBkejOLnfMeta } from "/builds/robustastudio/midea/miraco-website/pages/account/appointments/[id].vue?macro=true";
import { default as indexW9q1Zw31KjMeta } from "/builds/robustastudio/midea/miraco-website/pages/account/appointments/index.vue?macro=true";
import { default as devicesMGAupW14B4Meta } from "/builds/robustastudio/midea/miraco-website/pages/account/devices.vue?macro=true";
import { default as emailPp9italkceMeta } from "/builds/robustastudio/midea/miraco-website/pages/account/edit/email.vue?macro=true";
import { default as indexAO0IWgFSPNMeta } from "/builds/robustastudio/midea/miraco-website/pages/account/edit/index.vue?macro=true";
import { default as passwordsRraVKW5pAMeta } from "/builds/robustastudio/midea/miraco-website/pages/account/edit/password.vue?macro=true";
import { default as indexTXv25R2prfMeta } from "/builds/robustastudio/midea/miraco-website/pages/account/index.vue?macro=true";
import { default as _91id_93T7au7oz4x5Meta } from "/builds/robustastudio/midea/miraco-website/pages/account/orders/[id].vue?macro=true";
import { default as indexz5ZlJF82HLMeta } from "/builds/robustastudio/midea/miraco-website/pages/account/orders/index.vue?macro=true";
import { default as wishlistTjTFNboLndMeta } from "/builds/robustastudio/midea/miraco-website/pages/account/wishlist.vue?macro=true";
import { default as accountDxEtxSfmB7Meta } from "/builds/robustastudio/midea/miraco-website/pages/account.vue?macro=true";
import { default as recoverjYzZIyDv28Meta } from "/builds/robustastudio/midea/miraco-website/pages/auth/recover.vue?macro=true";
import { default as best_45fitEyGfUA7QKSMeta } from "/builds/robustastudio/midea/miraco-website/pages/best-fit.vue?macro=true";
import { default as book_45appointment_45introshu4wCwZDRMeta } from "/builds/robustastudio/midea/miraco-website/pages/book-appointment-intro.vue?macro=true";
import { default as book_45appointmentQErRo8lVTDMeta } from "/builds/robustastudio/midea/miraco-website/pages/book-appointment.vue?macro=true";
import { default as _91slug_933accSntaasMeta } from "/builds/robustastudio/midea/miraco-website/pages/brands/[slug].vue?macro=true";
import { default as indexlTCPotD94CMeta } from "/builds/robustastudio/midea/miraco-website/pages/brands/index.vue?macro=true";
import { default as careersS6hCrYdVnAMeta } from "/builds/robustastudio/midea/miraco-website/pages/careers.vue?macro=true";
import { default as carrier_45smart_45homeRVfGaQGLbBMeta } from "/builds/robustastudio/midea/miraco-website/pages/carrier-smart-home.vue?macro=true";
import { default as cartCGG3elpynbMeta } from "/builds/robustastudio/midea/miraco-website/pages/cart.vue?macro=true";
import { default as guest_45shipping4EJnhwZtrpMeta } from "/builds/robustastudio/midea/miraco-website/pages/checkout/guest-shipping.vue?macro=true";
import { default as paysXpkl0APpXMeta } from "/builds/robustastudio/midea/miraco-website/pages/checkout/pay.vue?macro=true";
import { default as shippingOnWe4SzYlfMeta } from "/builds/robustastudio/midea/miraco-website/pages/checkout/shipping.vue?macro=true";
import { default as comparegyFuv2I7H9Meta } from "/builds/robustastudio/midea/miraco-website/pages/compare.vue?macro=true";
import { default as contact2Cc6HLITSAMeta } from "/builds/robustastudio/midea/miraco-website/pages/contact.vue?macro=true";
import { default as dealsOglyMxD8HwMeta } from "/builds/robustastudio/midea/miraco-website/pages/deals.vue?macro=true";
import { default as errorSgjPnoIuXeMeta } from "/builds/robustastudio/midea/miraco-website/pages/error.vue?macro=true";
import { default as _91slug_930fqr00X74ZMeta } from "/builds/robustastudio/midea/miraco-website/pages/faqs/[slug].vue?macro=true";
import { default as indexGQzdLVyFOsMeta } from "/builds/robustastudio/midea/miraco-website/pages/faqs/index.vue?macro=true";
import { default as successgBQ1GZErjRMeta } from "/builds/robustastudio/midea/miraco-website/pages/guest-order/[id]/success.vue?macro=true";
import { default as index5EgmnpQVs3Meta } from "/builds/robustastudio/midea/miraco-website/pages/index.vue?macro=true";
import { default as loginlcdfM6niREMeta } from "/builds/robustastudio/midea/miraco-website/pages/login.vue?macro=true";
import { default as midea_45smart_45homehaoNYDZrUVMeta } from "/builds/robustastudio/midea/miraco-website/pages/midea-smart-home.vue?macro=true";
import { default as create_45reviewRqXOWgqtxCMeta } from "/builds/robustastudio/midea/miraco-website/pages/orders/[id]/create-review.vue?macro=true";
import { default as successW4Y4NAtBP3Meta } from "/builds/robustastudio/midea/miraco-website/pages/orders/[id]/success.vue?macro=true";
import { default as error6FcJePP9cKMeta } from "/builds/robustastudio/midea/miraco-website/pages/orders/error.vue?macro=true";
import { default as privacy_45policyHixpxxZcfUMeta } from "/builds/robustastudio/midea/miraco-website/pages/privacy-policy.vue?macro=true";
import { default as productskAr3fDfmMPMeta } from "/builds/robustastudio/midea/miraco-website/pages/products.vue?macro=true";
import { default as register_45product_45introANKkwFfl1GMeta } from "/builds/robustastudio/midea/miraco-website/pages/register-product-intro.vue?macro=true";
import { default as register_45productelqz2oE1nLMeta } from "/builds/robustastudio/midea/miraco-website/pages/register-product.vue?macro=true";
import { default as register3yezkFRDYzMeta } from "/builds/robustastudio/midea/miraco-website/pages/register.vue?macro=true";
import { default as returns_45and_45refundsJMJZfNGT4vMeta } from "/builds/robustastudio/midea/miraco-website/pages/returns-and-refunds.vue?macro=true";
import { default as _91slug_93CFbWM3DqO5Meta } from "/builds/robustastudio/midea/miraco-website/pages/search-manuals/[slug].vue?macro=true";
import { default as indexnkw0X4lFRUMeta } from "/builds/robustastudio/midea/miraco-website/pages/search-manuals/index.vue?macro=true";
import { default as _91name_93FiJX9sW25bMeta } from "/builds/robustastudio/midea/miraco-website/pages/search/[name].vue?macro=true";
import { default as searchjGK3CMKQCvMeta } from "/builds/robustastudio/midea/miraco-website/pages/search.vue?macro=true";
import { default as smart_45homekl17P7N9UfMeta } from "/builds/robustastudio/midea/miraco-website/pages/smart-home.vue?macro=true";
import { default as storesQ6WcypOSHcMeta } from "/builds/robustastudio/midea/miraco-website/pages/stores.vue?macro=true";
import { default as terms_45and_45conditionsttxVYkcOMdMeta } from "/builds/robustastudio/midea/miraco-website/pages/terms-and-conditions.vue?macro=true";
import { default as test9M8cXAASakMeta } from "/builds/robustastudio/midea/miraco-website/pages/test.vue?macro=true";
import { default as TopProductsContainervbDmCFIbT6Meta } from "/builds/robustastudio/midea/miraco-website/pages/TopProductsContainer.vue?macro=true";
import { default as warranty_45infoec9POU1vgZMeta } from "/builds/robustastudio/midea/miraco-website/pages/warranty-info.vue?macro=true";
export default [
  {
    name: "icons-page",
    path: "/_icons",
    meta: {"layout":"svg-sprite"},
    component: () => import("/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/@nuxtjs+svg-sprite@1.0.2_magicast@0.3.4_rollup@3.29.4/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue").then(m => m.default || m)
  },
  {
    name: "slug-create-review___en___default",
    path: "/:slug(.*)*/create-review",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/[...slug]/create-review.vue").then(m => m.default || m)
  },
  {
    name: "slug-create-review___en",
    path: "/en/:slug(.*)*/create-review",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/[...slug]/create-review.vue").then(m => m.default || m)
  },
  {
    name: "slug-create-review___ar",
    path: "/ar/:slug(.*)*/create-review",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/[...slug]/create-review.vue").then(m => m.default || m)
  },
  {
    name: "slug___en___default",
    path: "/:slug(.*)*",
    meta: indexKYyuF1XOa0Meta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    meta: indexKYyuF1XOa0Meta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "slug___ar",
    path: "/ar/:slug(.*)*",
    meta: indexKYyuF1XOa0Meta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "about-us___en___default",
    path: "/about-us",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: "about-us___en",
    path: "/en/about-us",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: "about-us___ar",
    path: "/ar/about-us",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: accountDxEtxSfmB7Meta?.name,
    path: "/account",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-addresses-id-edit___en___default",
    path: "addresses/:id()/edit",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/addresses/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses___en___default",
    path: "addresses",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses-new___en___default",
    path: "addresses/new",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/addresses/new.vue").then(m => m.default || m)
  },
  {
    name: "account-appointments-id___en___default",
    path: "appointments/:id()",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/appointments/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-appointments___en___default",
    path: "appointments",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/appointments/index.vue").then(m => m.default || m)
  },
  {
    name: "account-devices___en___default",
    path: "devices",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/devices.vue").then(m => m.default || m)
  },
  {
    name: "account-edit-email___en___default",
    path: "edit/email",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/edit/email.vue").then(m => m.default || m)
  },
  {
    name: "account-edit___en___default",
    path: "edit",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/edit/index.vue").then(m => m.default || m)
  },
  {
    name: "account-edit-password___en___default",
    path: "edit/password",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/edit/password.vue").then(m => m.default || m)
  },
  {
    name: "account___en___default",
    path: "",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-id___en___default",
    path: "orders/:id()",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-orders___en___default",
    path: "orders",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-wishlist___en___default",
    path: "wishlist",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/wishlist.vue").then(m => m.default || m)
  }
]
  },
  {
    name: accountDxEtxSfmB7Meta?.name,
    path: "/en/account",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-addresses-id-edit___en",
    path: "addresses/:id()/edit",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/addresses/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses___en",
    path: "addresses",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses-new___en",
    path: "addresses/new",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/addresses/new.vue").then(m => m.default || m)
  },
  {
    name: "account-appointments-id___en",
    path: "appointments/:id()",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/appointments/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-appointments___en",
    path: "appointments",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/appointments/index.vue").then(m => m.default || m)
  },
  {
    name: "account-devices___en",
    path: "devices",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/devices.vue").then(m => m.default || m)
  },
  {
    name: "account-edit-email___en",
    path: "edit/email",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/edit/email.vue").then(m => m.default || m)
  },
  {
    name: "account-edit___en",
    path: "edit",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/edit/index.vue").then(m => m.default || m)
  },
  {
    name: "account-edit-password___en",
    path: "edit/password",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/edit/password.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-id___en",
    path: "orders/:id()",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-orders___en",
    path: "orders",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-wishlist___en",
    path: "wishlist",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/wishlist.vue").then(m => m.default || m)
  }
]
  },
  {
    name: accountDxEtxSfmB7Meta?.name,
    path: "/ar/account",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-addresses-id-edit___ar",
    path: "addresses/:id()/edit",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/addresses/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses___ar",
    path: "addresses",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses-new___ar",
    path: "addresses/new",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/addresses/new.vue").then(m => m.default || m)
  },
  {
    name: "account-appointments-id___ar",
    path: "appointments/:id()",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/appointments/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-appointments___ar",
    path: "appointments",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/appointments/index.vue").then(m => m.default || m)
  },
  {
    name: "account-devices___ar",
    path: "devices",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/devices.vue").then(m => m.default || m)
  },
  {
    name: "account-edit-email___ar",
    path: "edit/email",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/edit/email.vue").then(m => m.default || m)
  },
  {
    name: "account-edit___ar",
    path: "edit",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/edit/index.vue").then(m => m.default || m)
  },
  {
    name: "account-edit-password___ar",
    path: "edit/password",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/edit/password.vue").then(m => m.default || m)
  },
  {
    name: "account___ar",
    path: "",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-id___ar",
    path: "orders/:id()",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-orders___ar",
    path: "orders",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-wishlist___ar",
    path: "wishlist",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/account/wishlist.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "auth-recover___en___default",
    path: "/auth/recover",
    meta: recoverjYzZIyDv28Meta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/auth/recover.vue").then(m => m.default || m)
  },
  {
    name: "auth-recover___en",
    path: "/en/auth/recover",
    meta: recoverjYzZIyDv28Meta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/auth/recover.vue").then(m => m.default || m)
  },
  {
    name: "auth-recover___ar",
    path: "/ar/auth/recover",
    meta: recoverjYzZIyDv28Meta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/auth/recover.vue").then(m => m.default || m)
  },
  {
    name: "best-fit___en___default",
    path: "/best-fit",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/best-fit.vue").then(m => m.default || m)
  },
  {
    name: "best-fit___en",
    path: "/en/best-fit",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/best-fit.vue").then(m => m.default || m)
  },
  {
    name: "best-fit___ar",
    path: "/ar/best-fit",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/best-fit.vue").then(m => m.default || m)
  },
  {
    name: "book-appointment-intro___en___default",
    path: "/book-appointment-intro",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/book-appointment-intro.vue").then(m => m.default || m)
  },
  {
    name: "book-appointment-intro___en",
    path: "/en/book-appointment-intro",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/book-appointment-intro.vue").then(m => m.default || m)
  },
  {
    name: "book-appointment-intro___ar",
    path: "/ar/book-appointment-intro",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/book-appointment-intro.vue").then(m => m.default || m)
  },
  {
    name: "book-appointment___en___default",
    path: "/book-appointment",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/book-appointment.vue").then(m => m.default || m)
  },
  {
    name: "book-appointment___en",
    path: "/en/book-appointment",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/book-appointment.vue").then(m => m.default || m)
  },
  {
    name: "book-appointment___ar",
    path: "/ar/book-appointment",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/book-appointment.vue").then(m => m.default || m)
  },
  {
    name: "brands-slug___en___default",
    path: "/brands/:slug()",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/brands/[slug].vue").then(m => m.default || m)
  },
  {
    name: "brands-slug___en",
    path: "/en/brands/:slug()",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/brands/[slug].vue").then(m => m.default || m)
  },
  {
    name: "brands-slug___ar",
    path: "/ar/brands/:slug()",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/brands/[slug].vue").then(m => m.default || m)
  },
  {
    name: "brands___en___default",
    path: "/brands",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: "brands___en",
    path: "/en/brands",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: "brands___ar",
    path: "/ar/brands",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: "careers___en___default",
    path: "/careers",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/careers.vue").then(m => m.default || m)
  },
  {
    name: "careers___en",
    path: "/en/careers",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/careers.vue").then(m => m.default || m)
  },
  {
    name: "careers___ar",
    path: "/ar/careers",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/careers.vue").then(m => m.default || m)
  },
  {
    name: "carrier-smart-home___en___default",
    path: "/carrier-smart-home",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/carrier-smart-home.vue").then(m => m.default || m)
  },
  {
    name: "carrier-smart-home___en",
    path: "/en/carrier-smart-home",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/carrier-smart-home.vue").then(m => m.default || m)
  },
  {
    name: "carrier-smart-home___ar",
    path: "/ar/carrier-smart-home",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/carrier-smart-home.vue").then(m => m.default || m)
  },
  {
    name: "cart___en___default",
    path: "/cart",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: "cart___en",
    path: "/en/cart",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: "cart___ar",
    path: "/ar/cart",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: "checkout-guest-shipping___en___default",
    path: "/checkout/guest-shipping",
    meta: guest_45shipping4EJnhwZtrpMeta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/checkout/guest-shipping.vue").then(m => m.default || m)
  },
  {
    name: "checkout-guest-shipping___en",
    path: "/en/checkout/guest-shipping",
    meta: guest_45shipping4EJnhwZtrpMeta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/checkout/guest-shipping.vue").then(m => m.default || m)
  },
  {
    name: "checkout-guest-shipping___ar",
    path: "/ar/checkout/guest-shipping",
    meta: guest_45shipping4EJnhwZtrpMeta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/checkout/guest-shipping.vue").then(m => m.default || m)
  },
  {
    name: "checkout-pay___en___default",
    path: "/checkout/pay",
    meta: paysXpkl0APpXMeta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/checkout/pay.vue").then(m => m.default || m)
  },
  {
    name: "checkout-pay___en",
    path: "/en/checkout/pay",
    meta: paysXpkl0APpXMeta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/checkout/pay.vue").then(m => m.default || m)
  },
  {
    name: "checkout-pay___ar",
    path: "/ar/checkout/pay",
    meta: paysXpkl0APpXMeta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/checkout/pay.vue").then(m => m.default || m)
  },
  {
    name: "checkout-shipping___en___default",
    path: "/checkout/shipping",
    meta: shippingOnWe4SzYlfMeta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/checkout/shipping.vue").then(m => m.default || m)
  },
  {
    name: "checkout-shipping___en",
    path: "/en/checkout/shipping",
    meta: shippingOnWe4SzYlfMeta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/checkout/shipping.vue").then(m => m.default || m)
  },
  {
    name: "checkout-shipping___ar",
    path: "/ar/checkout/shipping",
    meta: shippingOnWe4SzYlfMeta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/checkout/shipping.vue").then(m => m.default || m)
  },
  {
    name: "compare___en___default",
    path: "/compare",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/compare.vue").then(m => m.default || m)
  },
  {
    name: "compare___en",
    path: "/en/compare",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/compare.vue").then(m => m.default || m)
  },
  {
    name: "compare___ar",
    path: "/ar/compare",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/compare.vue").then(m => m.default || m)
  },
  {
    name: "contact___en___default",
    path: "/contact",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___en",
    path: "/en/contact",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___ar",
    path: "/ar/contact",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "deals___en___default",
    path: "/deals",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/deals.vue").then(m => m.default || m)
  },
  {
    name: "deals___en",
    path: "/en/deals",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/deals.vue").then(m => m.default || m)
  },
  {
    name: "deals___ar",
    path: "/ar/deals",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/deals.vue").then(m => m.default || m)
  },
  {
    name: "error___en___default",
    path: "/error",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/error.vue").then(m => m.default || m)
  },
  {
    name: "error___en",
    path: "/en/error",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/error.vue").then(m => m.default || m)
  },
  {
    name: "error___ar",
    path: "/ar/error",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/error.vue").then(m => m.default || m)
  },
  {
    name: "faqs-slug___en___default",
    path: "/faqs/:slug()",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: "faqs-slug___en",
    path: "/en/faqs/:slug()",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: "faqs-slug___ar",
    path: "/ar/faqs/:slug()",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: "faqs___en___default",
    path: "/faqs",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: "faqs___en",
    path: "/en/faqs",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: "faqs___ar",
    path: "/ar/faqs",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: "guest-order-id-success___en___default",
    path: "/guest-order/:id()/success",
    meta: successgBQ1GZErjRMeta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/guest-order/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: "guest-order-id-success___en",
    path: "/en/guest-order/:id()/success",
    meta: successgBQ1GZErjRMeta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/guest-order/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: "guest-order-id-success___ar",
    path: "/ar/guest-order/:id()/success",
    meta: successgBQ1GZErjRMeta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/guest-order/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: "index___en___default",
    path: "/",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ar",
    path: "/ar",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login___en___default",
    path: "/login",
    meta: loginlcdfM6niREMeta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___en",
    path: "/en/login",
    meta: loginlcdfM6niREMeta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___ar",
    path: "/ar/login",
    meta: loginlcdfM6niREMeta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "midea-smart-home___en___default",
    path: "/midea-smart-home",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/midea-smart-home.vue").then(m => m.default || m)
  },
  {
    name: "midea-smart-home___en",
    path: "/en/midea-smart-home",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/midea-smart-home.vue").then(m => m.default || m)
  },
  {
    name: "midea-smart-home___ar",
    path: "/ar/midea-smart-home",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/midea-smart-home.vue").then(m => m.default || m)
  },
  {
    name: "orders-id-create-review___en___default",
    path: "/orders/:id()/create-review",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/orders/[id]/create-review.vue").then(m => m.default || m)
  },
  {
    name: "orders-id-create-review___en",
    path: "/en/orders/:id()/create-review",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/orders/[id]/create-review.vue").then(m => m.default || m)
  },
  {
    name: "orders-id-create-review___ar",
    path: "/ar/orders/:id()/create-review",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/orders/[id]/create-review.vue").then(m => m.default || m)
  },
  {
    name: "orders-id-success___en___default",
    path: "/orders/:id()/success",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/orders/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: "orders-id-success___en",
    path: "/en/orders/:id()/success",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/orders/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: "orders-id-success___ar",
    path: "/ar/orders/:id()/success",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/orders/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: "orders-error___en___default",
    path: "/orders/error",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/orders/error.vue").then(m => m.default || m)
  },
  {
    name: "orders-error___en",
    path: "/en/orders/error",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/orders/error.vue").then(m => m.default || m)
  },
  {
    name: "orders-error___ar",
    path: "/ar/orders/error",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/orders/error.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en___default",
    path: "/privacy-policy",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en",
    path: "/en/privacy-policy",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___ar",
    path: "/ar/privacy-policy",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "products___en___default",
    path: "/products",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/products.vue").then(m => m.default || m)
  },
  {
    name: "products___en",
    path: "/en/products",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/products.vue").then(m => m.default || m)
  },
  {
    name: "products___ar",
    path: "/ar/products",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/products.vue").then(m => m.default || m)
  },
  {
    name: "register-product-intro___en___default",
    path: "/register-product-intro",
    meta: register_45product_45introANKkwFfl1GMeta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/register-product-intro.vue").then(m => m.default || m)
  },
  {
    name: "register-product-intro___en",
    path: "/en/register-product-intro",
    meta: register_45product_45introANKkwFfl1GMeta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/register-product-intro.vue").then(m => m.default || m)
  },
  {
    name: "register-product-intro___ar",
    path: "/ar/register-product-intro",
    meta: register_45product_45introANKkwFfl1GMeta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/register-product-intro.vue").then(m => m.default || m)
  },
  {
    name: "register-product___en___default",
    path: "/register-product",
    meta: register_45productelqz2oE1nLMeta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/register-product.vue").then(m => m.default || m)
  },
  {
    name: "register-product___en",
    path: "/en/register-product",
    meta: register_45productelqz2oE1nLMeta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/register-product.vue").then(m => m.default || m)
  },
  {
    name: "register-product___ar",
    path: "/ar/register-product",
    meta: register_45productelqz2oE1nLMeta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/register-product.vue").then(m => m.default || m)
  },
  {
    name: "register___en___default",
    path: "/register",
    meta: register3yezkFRDYzMeta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___en",
    path: "/en/register",
    meta: register3yezkFRDYzMeta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___ar",
    path: "/ar/register",
    meta: register3yezkFRDYzMeta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "returns-and-refunds___en___default",
    path: "/returns-and-refunds",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/returns-and-refunds.vue").then(m => m.default || m)
  },
  {
    name: "returns-and-refunds___en",
    path: "/en/returns-and-refunds",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/returns-and-refunds.vue").then(m => m.default || m)
  },
  {
    name: "returns-and-refunds___ar",
    path: "/ar/returns-and-refunds",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/returns-and-refunds.vue").then(m => m.default || m)
  },
  {
    name: "search-manuals-slug___en___default",
    path: "/search-manuals/:slug()",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/search-manuals/[slug].vue").then(m => m.default || m)
  },
  {
    name: "search-manuals-slug___en",
    path: "/en/search-manuals/:slug()",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/search-manuals/[slug].vue").then(m => m.default || m)
  },
  {
    name: "search-manuals-slug___ar",
    path: "/ar/search-manuals/:slug()",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/search-manuals/[slug].vue").then(m => m.default || m)
  },
  {
    name: "search-manuals___en___default",
    path: "/search-manuals",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/search-manuals/index.vue").then(m => m.default || m)
  },
  {
    name: "search-manuals___en",
    path: "/en/search-manuals",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/search-manuals/index.vue").then(m => m.default || m)
  },
  {
    name: "search-manuals___ar",
    path: "/ar/search-manuals",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/search-manuals/index.vue").then(m => m.default || m)
  },
  {
    name: "search___en___default",
    path: "/search",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/search.vue").then(m => m.default || m),
    children: [
  {
    name: "search-name___en___default",
    path: ":name()",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/search/[name].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/search.vue").then(m => m.default || m),
    children: [
  {
    name: "search-name___en",
    path: ":name()",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/search/[name].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "search___ar",
    path: "/ar/search",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/search.vue").then(m => m.default || m),
    children: [
  {
    name: "search-name___ar",
    path: ":name()",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/search/[name].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "smart-home___en___default",
    path: "/smart-home",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/smart-home.vue").then(m => m.default || m)
  },
  {
    name: "smart-home___en",
    path: "/en/smart-home",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/smart-home.vue").then(m => m.default || m)
  },
  {
    name: "smart-home___ar",
    path: "/ar/smart-home",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/smart-home.vue").then(m => m.default || m)
  },
  {
    name: "stores___en___default",
    path: "/stores",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/stores.vue").then(m => m.default || m)
  },
  {
    name: "stores___en",
    path: "/en/stores",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/stores.vue").then(m => m.default || m)
  },
  {
    name: "stores___ar",
    path: "/ar/stores",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/stores.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___en___default",
    path: "/terms-and-conditions",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___en",
    path: "/en/terms-and-conditions",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___ar",
    path: "/ar/terms-and-conditions",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "test___en___default",
    path: "/test",
    meta: test9M8cXAASakMeta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/test.vue").then(m => m.default || m)
  },
  {
    name: "test___en",
    path: "/en/test",
    meta: test9M8cXAASakMeta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/test.vue").then(m => m.default || m)
  },
  {
    name: "test___ar",
    path: "/ar/test",
    meta: test9M8cXAASakMeta || {},
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/test.vue").then(m => m.default || m)
  },
  {
    name: "TopProductsContainer___en___default",
    path: "/TopProductsContainer",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/TopProductsContainer.vue").then(m => m.default || m)
  },
  {
    name: "TopProductsContainer___en",
    path: "/en/TopProductsContainer",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/TopProductsContainer.vue").then(m => m.default || m)
  },
  {
    name: "TopProductsContainer___ar",
    path: "/ar/TopProductsContainer",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/TopProductsContainer.vue").then(m => m.default || m)
  },
  {
    name: "warranty-info___en___default",
    path: "/warranty-info",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/warranty-info.vue").then(m => m.default || m)
  },
  {
    name: "warranty-info___en",
    path: "/en/warranty-info",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/warranty-info.vue").then(m => m.default || m)
  },
  {
    name: "warranty-info___ar",
    path: "/ar/warranty-info",
    component: () => import("/builds/robustastudio/midea/miraco-website/pages/warranty-info.vue").then(m => m.default || m)
  }
]